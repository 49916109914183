import React from "react"
import Helmet from "react-helmet"
import { Link } from "gatsby"
import YouTube from "react-youtube"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

import "../../styles/games.scss"
import "../../styles/game-page.scss"

// Images
import bg from "../../images/data/wonhon/meta/bg.jpg"

import keyart from "../../images/data/wonhon/hero/keyart.jpg"
import keyart_thumbnail from "../../images/data/wonhon/hero/keyart-thumbnail.jpg"
import gameplay_thumbnail from "../../images/data/wonhon/hero/gameplay-thumbnail.jpg"

import game_about from "../../images/data/wonhon/meta/icon.jpg"
import game_logo from "../../images/data/wonhon/meta/logo.png"
import game_developer from "../../images/data/wonhon/meta/developer.png"

import slide_1 from "../../images/data/wonhon/slider/a.jpg"
import slide_2 from "../../images/data/wonhon/slider/b.jpg"
import slide_3 from "../../images/data/wonhon/slider/c.jpg"
import slide_4 from "../../images/data/wonhon/slider/d.jpg"
import slide_5 from "../../images/data/wonhon/slider/e.jpg"
import slide_6 from "../../images/data/wonhon/slider/f.jpg"
import slide_7 from "../../images/data/wonhon/slider/g.jpg"
import slide_8 from "../../images/data/wonhon/slider/h.jpg"

import press1 from "../../images/data/wonhon/press/1.png"

import thumbnail_1 from "../../images/data/metaphora/thumbnail.jpg"
import thumbnail_2 from "../../images/data/retro-machina/thumbnail.jpg"
import thumbnail_3 from "../../images/data/raji/thumbnail.jpg"

import game_logo_1 from "../../images/data/metaphora/meta/logo.png"
import game_logo_2 from "../../images/data/retro-machina/meta/logo.png"
import game_logo_3 from "../../images/data/raji/meta/logo.png"

// Videos
import video from "../../videos/wonhon.mp4"

const GamePage = () => {

  return (
    <Layout>

      <Helmet>
        <body className="game-page"/>
      </Helmet>

      <SEO title="Wonhon: A Vengeful Spirit"/>
      <Link to="/" className="logo" activeClassName="-active">Super!com</Link>
      <Link to="/games/" className="title">Games</Link>

      <div className="games">

        <div className="center">

          <section className="games__card -alone">

            <div className="games__card__bg" style={{backgroundImage: 'url(' + bg + ')'}}></div>
            
            <h3 className="games__card__title">
              <span className="games__card__title__link">
                WONHON: <br/>
                A VENGEFUL SPIRIT
                <span className="games__card__title__link__released">
                  <span>Q4 2020</span>
                </span>
              </span>
            </h3>

            <div className="games__card__content">
              <div className="games__card__video">
                <div className="games__card__video__visible">
                  <img src={keyart} className="games__card__video__visible__size-setter"  alt=""/>
                  <div className="games__card__video__visible__item" data-id="keyart">
                    <video muted="muted" loop="loop" playsInline="playsinline" autoPlay="autoplay">
                      <source src={video} type="video/mp4"/>
                    </video>
                    <img src={keyart} className="games__card__video__visible__item__media"  alt=""/>
                  </div>
                  <div className="games__card__video__visible__item" data-id="gameplay">
                    <YouTube
                      videoId="DX3307Prgw0"
                      opts={{
                        playerVars: {
                          controls: 0,
                          playlist: 'DX3307Prgw0',
                          loop: 1,
                          showinfo: 0,
                          autoplay: 1,
                          start: 0,
                          mute: 1,
                          enablejsapi: 1
                        }
                      }}
                      // onReady={_onReady}
                    ></YouTube>
                  </div>
                </div>
                <button className="games__card__video__button -key-art -active" data-id="keyart" style={{backgroundImage: 'url(' + keyart_thumbnail + ')'}}>
                  <span className="games__card__video__button__name">Key Art</span>
                </button>
                <button className="games__card__video__button -gameplay" data-id="gameplay" style={{backgroundImage: 'url(' + gameplay_thumbnail + ')'}}>
                  <span className="games__card__video__button__name">Gameplay</span>
                </button>
              </div>
            </div>

            <footer className="games__card__footer">
              <div className="games__card__footer__about">
                <div className="games__card__footer__about__image">
                  <img src={game_about} alt="wonhon"/>
                </div>
                <div className="games__card__footer__about__text">
                  Created by a solo developer, Wonhon is a paranormal stealth-action and tactics game about a young Korean girl killed in a military conflict and returned from the dead with supernatural powers to exact revenge for herself and her people.
                  <div className="games__card__footer__about__text__social">
                    <a href="https://www.youtube.com/channel/UC7CHKZWCmoVGQok03k1n4nQ" className="games__card__footer__about__text__social__item -youtube" target="_blank" rel="noopener noreferrer">youtube</a>
                    <a href="https://www.facebook.com/pg/Wonhon-107322611088052/" className="games__card__footer__about__text__social__item -facebook" target="_blank" rel="noopener noreferrer">facebook</a>
                    <a href="https://twitter.com/wonhonthegame" className="games__card__footer__about__text__social__item -twitter" target="_blank" rel="noopener noreferrer">twitter</a>
                    <a href="https://www.instagram.com/wonhonthegame/" className="games__card__footer__about__text__social__item -instagram" target="_blank" rel="noopener noreferrer">instagram</a>
                    <a href="https://discord.gg/8Sama6w" className="games__card__footer__about__text__social__item -discord" target="_blank" rel="noopener noreferrer">discord</a>
                  </div>
                  <div className="games__card__footer__about__text__developer">
                    <div className="games__card__footer__about__text__developer__label">Game developer</div>
                    <Link to="/" className="games__card__footer__about__text__developer__logo">
                      <img src={game_developer} alt=""/>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="games__card__footer__stores">
                <div className="games__card__footer__stores__line">
                  <a href="https://store.steampowered.com/app/1294340/Wonhon__a_Vengeful_Spirit" className="games__card__footer__stores__line__item -steam" target="_blank" rel="noopener noreferrer">steam</a>
                </div>
              </div>
              <a href="https://wonhon-game.com/" className="games__card__footer__logo" target="_blank" rel="noopener noreferrer">
                <img src={game_logo} className="games__card__footer__logo" alt="wonhon"/>
              </a>
            </footer>
            
          </section>

        </div>

      </div>

      <section className="game-section">
        <div className="center">

          <h4 className="game-section__title">
            <i></i>
            <span className="game-section__title__text">
              Key <span className="game-section__title__text__accent">Features</span>
            </span>
          </h4>

          <div className="game-section__content">

            <div className="game-section__features">
              <div className="game-section__features__item">
                <b>Use an array of powers to stealthily <br/>
                dispose of your enemies</b> or sow chaos <br/>
                by embracing your supernatural nature
              </div>
              <div className="game-section__features__item">
                Use shape-shifting abilities to your tactical <br/>
                advantage: <b>take on the form of a little girl <br/>
                or a horrific ghost</b>
              </div>
              <div className="game-section__features__item">
                <b>Take control of your enemies by absorbing <br/>
                their Qi</b> and force them to attack other enemies <br/>
                enemies in the action-packed combat
              </div>
            </div>

            <div className="game-section__slider -collapsed">
              <div className="glide">
                <div className="glide__track" data-glide-el="track">
                  <ul className="glide__slides">
                    <li className="glide__slide">
                      <img src={slide_1} alt="" data-index="0"/>
                    </li>
                    <li className="glide__slide">
                      <img src={slide_2} alt="" data-index="1"/>
                    </li>
                    <li className="glide__slide">
                      <img src={slide_3} alt="" data-index="2"/>
                    </li>
                    <li className="glide__slide">
                      <img src={slide_4} alt="" data-index="3"/>
                    </li>
                    <li className="glide__slide">
                      <img src={slide_5} alt="" data-index="4"/>
                    </li>
                    <li className="glide__slide">
                      <img src={slide_6} alt="" data-index="5"/>
                    </li>
                    <li className="glide__slide">
                      <img src={slide_7} alt="" data-index="6"/>
                    </li>
                    <li className="glide__slide">
                      <img src={slide_8} alt="" data-index="7"/>
                    </li>
                  </ul>
                </div>
                <div className="glide__arrows" data-glide-el="controls">
                  <button className="glide__arrow glide__arrow--left" data-glide-dir="<">prev</button>
                  <button className="glide__arrow glide__arrow--right" data-glide-dir=">">next</button>
                </div>
              </div>
            </div>

          </div>

        </div>
      </section>

      <section className="game-section">
        <div className="center">

          <h4 className="game-section__title">
            <i></i>
            <span className="game-section__title__text">
              Game <span className="game-section__title__text__accent">Trailer</span>
            </span>
          </h4>

          <div className="game-section__content -trailer">
            <YouTube
              videoId="1cFrtI5ndts"
              opts={{
                playerVars: {
                  controls: 1,
                  playlist: '1cFrtI5ndts',
                  loop: 1,
                  showinfo: 0,
                  autoplay: 0,
                  start: 0,
                  mute: 1,
                  enablejsapi: 1
                }
              }}
              // onReady={_onReady}
            ></YouTube>
          </div>

        </div>
      </section>

      {/* <section className="game-section">
        <div className="center">

          <h4 className="game-section__title">
            <i></i>
            <span className="game-section__title__text">
              Awards <span className="game-section__title__text__accent">We Are Proud Of</span>
            </span>
          </h4>

          <div className="game-section__content">
            <div className="game-section__awards">
              <div className="game-section__awards__item">
                <img src={award_1} alt=""/>
              </div>
            </div>
          </div>

        </div>
      </section> */}

      <section className="game-section">
        <div className="center">

          <h4 className="game-section__title">
            <i></i>
            <span className="game-section__title__text">
              Press <span className="game-section__title__text__accent">About Us</span>
            </span>
          </h4>

          <div className="game-section__content">
            
            <div className="game-section__quotes-group">
              <div className="game-section__quote">
                <img src={game_logo} className="game-section__quote__image" alt=""/>
                <Link to="https://youtu.be/VjQjyTi8kjE" className="game-section__quote__text" target="_blank" rel="noopener noreferrer">
                  Ein Geistermädchen für Taktik-Füchse - Wonhon: A Vengeful Spirit #gamescom2020
                </Link>
              </div>
              <div className="game-section__quote">
                <img src={game_logo} className="game-section__quote__image" alt=""/>
                <Link to="https://www.ign.com/videos/wonhon-a-vengeful-spirit-trailer-will-have-you-possessing-the-living-to-avenge-the-dead" className="game-section__quote__text" target="_blank" rel="noopener noreferrer">
                  Wonhon: A Vengeful Spirit Trailer Will Have You Possessing the Living to Avenge the Dead
                </Link>
              </div>
            </div>

            <div className="game-section__image">
              <img src={press1} alt=""/>
            </div>
            
            <div className="game-section__quotes-group">
              <div className="game-section__quote">
                <img src={game_logo} className="game-section__quote__image" alt=""/>
                <Link to="https://www.igromania.ru/news/97283/Mstitelnyy_duh_v_oficialnom_treylere_Wonhon_A_­Vengeful_Spirit.html" className="game-section__quote__text" target="_blank" rel="noopener noreferrer">
                  Мстительный дух в официальном трейлере Wonhon: A Vengeful Spirit
                </Link>
              </div>
            </div>

            <div className="game-section__press-kit">
              <div className="game-section__press-kit__bg" style={{backgroundImage: 'url(' + bg + ')'}}></div>
              <a href="https://app.box.com/s/4jr4ssa2twqn4o58fuu6vptosi4kyd41" className="game-section__press-kit__link" target="_blank" rel="noopener noreferrer">
                <span className="game-section__press-kit__link__accent-text">Press <br/>Kit</span>
                <span className="game-section__press-kit__link__text">Let's see</span>
              </a>
            </div>

            <button className="content__creator">
              <span className="content__creator__text">If you are content creator</span>
            </button>
            
          </div>

        </div>
      </section>

      <section className="game-section">
        <div className="center">

          <h4 className="game-section__title -small">
            <i></i>
            <span className="game-section__title__text">
              You may like our other games
            </span>
          </h4>

          <div className="game-section__content">

            <div className="game-section__recents">
              <Link to="/games/metaphora/" className="game-section__recents__item" style={{backgroundImage: 'url(' + thumbnail_1 + ')'}}>
                <span className="game-section__recents__item__title">
                  <span>METAPHORA THE MOONY-CAT QUEST</span>
                </span>
                <span className="game-section__recents__item__text">
                  An animated action-adventure with catching
                  story about regenerating the world
                </span>
                <img src={game_logo_1} className="game-section__recents__item__image" alt=""/>
              </Link>
              <Link to="/games/retro-machina/" className="game-section__recents__item" style={{backgroundImage: 'url(' + thumbnail_2 + ')'}}>
                <span className="game-section__recents__item__title">
                  <span>Retro Machina</span>
                </span>
                <span className="game-section__recents__item__text">
                  Retro Machina is a single-player action game 
                  with puzzle elements in a retro-futuristic setting.
                </span>
                <img src={game_logo_2} className="game-section__recents__item__image" alt=""/>
              </Link>
              <Link to="/games/raji/" className="game-section__recents__item" style={{backgroundImage: 'url(' + thumbnail_3 + ')'}}>
                <span className="game-section__recents__item__title">
                  <span>RAJI: AN ANCIENT EPIC</span>
                </span>
                <span className="game-section__recents__item__text">
                  Raji: An Anсient Epic is an action-adventure
                  game set in ancient India.
                </span>
                <img src={game_logo_3} className="game-section__recents__item__image" alt=""/>
              </Link>
            </div>

            <div className="games__promo__button">
              <button className="games__promo__button__link" data-role="call-to-action">
                <span className="games__promo__button__link__text">Give a boost to your game</span>
              </button>
              <div className="games__promo__button__shadow"></div>
            </div>

          </div>

        </div>
      </section>

      <div className="game-section__slider -expanded">
        <div className="glide">
          <div className="glide__track" data-glide-el="track">
            <ul className="glide__slides">
              <li className="glide__slide">
                <img src={slide_1} alt="" data-index="0"/>
              </li>
              <li className="glide__slide">
                <img src={slide_2} alt="" data-index="1"/>
              </li>
              <li className="glide__slide">
                <img src={slide_3} alt="" data-index="2"/>
              </li>
              <li className="glide__slide">
                <img src={slide_4} alt="" data-index="3"/>
              </li>
              <li className="glide__slide">
                <img src={slide_5} alt="" data-index="4"/>
              </li>
              <li className="glide__slide">
                <img src={slide_6} alt="" data-index="5"/>
              </li>
              <li className="glide__slide">
                <img src={slide_7} alt="" data-index="6"/>
              </li>
              <li className="glide__slide">
                <img src={slide_8} alt="" data-index="7"/>
              </li>
            </ul>
          </div>
          <div className="glide__arrows" data-glide-el="controls">
            <button className="glide__arrow glide__arrow--left" data-glide-dir="<">prev</button>
            <button className="glide__arrow glide__arrow--right" data-glide-dir=">">next</button>
          </div>
        </div>
      </div>
      
    </Layout>
  );

}

export default GamePage
